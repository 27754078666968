import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import classNames from "classnames";
import Modal from "react-overlays/Modal";
import { Transition } from "react-transition-group";
import Icon from "./atoms/icon";
import Button from "./atoms/button";

const FADE_DURATION = 500;
const fadeStyles = {
  entering: "show",
  entered: "show",
};

const Fade = ({ children, ...props }) => (
  <Transition {...props} timeout={FADE_DURATION}>
    {(status, innerProps) =>
      React.cloneElement(children, {
        ...innerProps,
        className: `fade ${fadeStyles[status]} ${children.props.className}`,
      })
    }
  </Transition>
);

const SiteModal = ({ show, onHide, className, buttonClose, children }) => {
  const renderBackdrop = (props) => <div className="backdrop" {...props} />;

  const { labelManagerLabels } = useStaticQuery(graphql`
    {
      labelManagerLabels: labelManagerLabels {
        close
      }
    }
  `);

  const btnLabel = { label: labelManagerLabels.close };
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="modal-label"
      className={classNames("modal", className)}
      style={{
        "--transition-duration": `${FADE_DURATION}ms`,
      }}
      transition={Fade}
      backdropTransition={Fade}
      renderBackdrop={renderBackdrop}
      aria-hidden="false"
      aria-modal="true"
    >
      <div className="modal__body">
        <button
          type="button"
          onClick={onHide}
          className="modal__close"
          aria-label={labelManagerLabels.close}
        >
          <Icon name="symbols-close" />
        </button>
        {children}

        {buttonClose && (
          <div className="button-modal-position">
            <Button className="libretto-button secondary" onClick={onHide} data={btnLabel}></Button>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default SiteModal;
