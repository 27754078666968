const withStartSlash = (path) => (path ? (path.startsWith("/") ? path : `/${path}`) : path);
const withEndSlash = (path) => (path ? (path.endsWith("/") ? path : `${path}/`) : path);
const normalizeRelativePath = (path) => {
  const path1 = withStartSlash(path);
  if (!path1) return path1;
  const searchIndex = path1.indexOf("?");
  return searchIndex >= 0
    ? withEndSlash(path1.substring(0, searchIndex)) + path1.substring(searchIndex)
    : withEndSlash(path1);
};

const normalizeJoinPaths = (...paths) => {
  return `/${paths
    .map((path) => (path.startsWith("/") ? path.substring(1) : path))
    .map((path) => (path.endsWith("/") ? path.substring(0, path.length - 1) : path))
    .join("/")}/`;
};

module.exports = {
  normalizeRelativePath,
  normalizeJoinPaths,
};
